import RedesignDescriptor from './funding-redesign';
import RedesignCardDetailsDescriptor from './cardDetails-redesign';
import RedesignCreditCardDescriptor from './creditCard-redesign';
import CanteenDescriptor from './funding-canteen';

import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';

const typeDescriber = {
  [BuildTypes.canteen]: CanteenDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.companykitchen]: CanteenDescriptor,
  [BuildTypes.refive]: RedesignDescriptor,
};

const cardDetailsDescriptor = {
  [BuildTypes.canteen]: RedesignCardDetailsDescriptor,
  [BuildTypes.default]: RedesignCardDetailsDescriptor,
  [BuildTypes.companykitchen]: RedesignCardDetailsDescriptor,
  [BuildTypes.refive]: RedesignCardDetailsDescriptor,
};

const creditCardDescriptor = {
  [BuildTypes.canteen]: RedesignCreditCardDescriptor,
  [BuildTypes.default]: RedesignCreditCardDescriptor,
  [BuildTypes.companykitchen]: RedesignCreditCardDescriptor,
  [BuildTypes.refive]: RedesignCreditCardDescriptor,
};

export function getFundingDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}

export function getCardDetailsDescriber() {
  return cardDetailsDescriptor[
    Settings.buildType as keyof typeof cardDetailsDescriptor
  ];
}

export function getCreditCardDescriber() {
  return creditCardDescriptor[
    Settings.buildType as keyof typeof creditCardDescriptor
  ];
}

export interface AccountFundingDescriberData {
  styles: {[index: string]: {[index: string]: number | string}};
  title?: string;
  onClose: () => void;
}
