import Bowser from 'bowser';
import ExpoConstants from 'expo-constants';
const version = '10.8.1';
const buildNumber = '2025181101';
type BrowserInfo = {
  browser: {
    name: string;
    version: string;
  };
  os: {
    name: string;
    version: string;
    versionName: string;
  };
  platform: {
    type: string;
  };
  engine: {
    name: string;
    version: string;
  };
};

class DeviceInfo {
  getBrowserInfo(): any {
    return Bowser.parse(window.navigator.userAgent);
  }

  async getModel(): Promise<string> {
    return this.getBrowserInfo().browser.name;
  }

  isEmulatorSync(): boolean {
    return true;
  }

  async getSystemName(): Promise<string> {
    return this.getBrowserInfo().os.name;
  }

  async getVersion(): Promise<string> {
    return version;
  }

  async getSystemVersion(): Promise<string> {
    return this.getBrowserInfo().os.versionName;
  }

  async getBuildNumber(): Promise<string> {
    return buildNumber;
  }

  getApplicationName(): string {
    return ExpoConstants.manifest.name;
  }
}

export default new DeviceInfo();
