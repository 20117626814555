import * as React from 'react';
import {StyleSheet, View, ScrollView} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import Localized from 'src/constants/AppStrings';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import KeyboardAvoidingView from '../../elements/365KeyboardAvoidingView';
import BackSubheader from '../../elements/BackSubheader';
import CreditCardInput from '../../elements/funding/CreditCardInput';
import AVTextInput from '../../elements/AVTextInput';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import type {CCModel} from 'src/types/CCModel';
import ActionsFactory from 'src/actions/ActionsFactory';
import PersistentStore from 'src/services/PersistentStoreService';
import CreditCardHelper from 'src/services/CreditCardService';
import {alertError, alertSuccess} from '../../helpers/AlertHelper';
import {connect} from 'react-redux';
import {RootState} from 'src/redux/store';
import {PaymentCredentials} from 'src/models/PaymentCredentials';
import {CreditCard} from 'src/models/CreditCard';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Settings from 'src/Settings';
import AVText from 'src/components/elements/AVText';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import {getCreditCardDescriber} from 'src/components/screens/funding/descriptor/DescriptorType';
import moment from 'moment';
import CustomToggleSwitch from 'src/components/elements/CustomToggleSwitch';

type CreditCardScreenState = {
  addManual: boolean;
  creditCard: string;
  expireMonth: string;
  expiryDate: string;
  cvv: string;
  expireYear: string;
  issuer: string;
  redactedCardNumber: string;
  zip: string;
  cardScanned: boolean;
  defaultTokenStatus: boolean;
  isDefaultTokenDisabled: boolean;
  previousRoute: string | null;
  cardNumberError: string | null;
  expDateError: string | null;
  cvvError: string | null;
  zipCodeError: string | null;
  isActive: boolean;
  allowChangeText: boolean;
};
export type CreditCardScreenProps = {
  cardAdded: () => void;
  buttonText: string;
  paymentCredentials: PaymentCredentials;
  creditCards: Array<CreditCard>;
  navigation?: NavigationProp<CreditCardScreen>;
  selectedLocation?: string;
};

class CreditCardScreen extends React.Component<
  CreditCardScreenProps,
  CreditCardScreenState
> {
  scrollView: ScrollView | null;
  year: AVTextInput | null;
  month: AVTextInput | null;
  cvv: AVTextInput | null;
  zipCode: AVTextInput | null;

  static defaultProps = {
    buttonText: Localized.Buttons.save,
    cardAdded: null,
  };
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: CreditCardScreenProps) {
    super(props);
    this.state = {
      addManual: false,
      creditCard: '',
      issuer: '',
      expiryDate: '',
      expireMonth: '',
      allowChangeText: true,
      expireYear: '',
      cvv: '',
      redactedCardNumber: '',
      zip: '',
      cardScanned: false,
      defaultTokenStatus: this.props.creditCards.length === 0,
      isDefaultTokenDisabled: this.props.creditCards.length === 0,
      previousRoute: null,
      cardNumberError: null,
      expDateError: null,
      cvvError: null,
      zipCodeError: null,
      isActive: false,
    };
    this.validate = this.validate.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onAddManuallyPress = this.onAddManuallyPress.bind(this);
    this.scanClick = this.scanClick.bind(this);
    this.didScanCard = this.didScanCard.bind(this);
    this.onCloseSelect = this.onCloseSelect.bind(this);
    this.cardNumberAction = this.cardNumberAction.bind(this);
    this.expireDateAction = this.expireDateAction.bind(this);
    this.changeTxtAction = this.changeTxtAction.bind(this);
    this.cvvAction = this.cvvAction.bind(this);
    this.zipAction = this.zipAction.bind(this);
    this.switchAction = this.switchAction.bind(this);
    this.validateCardData = this.validateCardData.bind(this);
    this.checkExpiredDate = this.checkExpiredDate.bind(this);
    this.expireMonthAction = this.expireMonthAction.bind(this);
    this.expireYearAction = this.expireYearAction.bind(this);
  }

  componentDidMount() {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
  }

  onAddManuallyPress() {
    this.setState({
      addManual: true,
    });
    FirebaseAnalytic.trackEvent('AddCreditCardManually', 'CreditCardScreen', {
      ...this.props,
      ...this.state,
    });
  }

  onCloseSelect() {
    Events.AddCard.trackEvent('CANCEL');
    NavActions.pop();
  }

  validate(ccModel: CCModel) {
    if (
      !ccModel.CardNumber ||
      !ccModel.ExpirationMonth ||
      !ccModel.ExpirationYear ||
      !ccModel.CvvCode ||
      !ccModel.BillingZip
    ) {
      return Localized.Errors.all_fields_required;
    }

    return null;
  }

  async handleClick() {
    const cardNumber = this.state.creditCard;
    const {expireMonth, cvv} = this.state;
    let {expireYear, issuer} = this.state;
    const accountId = await PersistentStore.getAccountId();

    if (expireYear && expireYear.length === 2) {
      expireYear = `20${expireYear}`;
    }

    if (!issuer && cardNumber) {
      issuer = CreditCardHelper.getCardType(cardNumber);
    }

    const ccModel = {
      CardNumber: cardNumber,
      ExpirationMonth: expireMonth as unknown as number,
      ExpirationYear: expireYear as unknown as number,
      CvvCode: cvv,
      CardNumberMask: this.state.redactedCardNumber,
      BillingZip: this.state.zip,
      AccountId: accountId || '',
      Issuer: issuer,
    };
    const errorMessage = this.validate(ccModel);

    if (errorMessage) {
      alertError(errorMessage);

      Events.Error.trackEvent(
        'Exception',
        'CreditCardScreen: validate(ccModel)',
        errorMessage,
      );
    } else {
      this.context.actions.showSpinner();

      try {
        const response = await ActionsFactory.getAccountActions().addCreditCard(
          ccModel,
          this.props.paymentCredentials,
        );

        if (response) {
          Events.AddCard.trackEvent('ADDED');

          if (this.state.defaultTokenStatus) {
            await ActionsFactory.getAccountActions().updateDefaultToken(
              response.accountId,
              response.accountBalanceId,
              response.balanceTokenId,
            );
          }

          alertSuccess(Localized.Success.credit_card_added);
          this.context.actions.hideSpinner();

          if (this.props.cardAdded) {
            this.props.cardAdded();
          } else {
            NavActions.pop();
          }
        } else {
          throw new Error('Invalid Token Response');
        }
      } catch (error) {
        if (error?.statusCode === 429) {
          alertError(Localized.Errors.please_try_again_shortly);
          return;
        }

        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'CreditCardScreen:HandleClick',
          generateErrorMessage(error),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'CreditCardScreen:HandleClick',
          generateErrorMessage(error),
          guid,
        );
        alertError(Localized.Errors.error_adding_credit_card, guid);
      } finally {
        this.context.actions.hideSpinner();
      }
    }
  }

  scanClick() {
    this.setState({
      addManual: false,
    });
    FirebaseAnalytic.trackEvent('ScanClick', 'CreditCardScreen', {
      ...this.props,
      ...this.state,
    });
  }

  didScanCard(result: any) {
    this.setState({
      creditCard: result.cardNumber,
      cardScanned: true,
      expireMonth: result.expiryMonth,
      expireYear: result.expiryYear,
      cvv: result.cvv,
      redactedCardNumber: result.redactedCardNumber,
      issuer: result.cardType,
    });
  }

  focusInput(input: AVTextInput | null) {
    if (input) {
      input.focus();
    }
  }

  cardNumberAction(value: string): void {
    this.setState({creditCard: value}, () => {
      this.validateCardData(false, 'card');
    });
  }
  changeTxtAction(value?: boolean): void {
    this.setState({
      allowChangeText: value,
    });
  }

  expireDateAction(value: string): void {
    if (value.length > 5) {
      return;
    }
    const date =
      value.length >= 3 && !value.includes('/')
        ? `${value.substring(0, 2)}/${value.substring(2)}`
        : value;

    const dateValue = date.split('/');
    let month = '';
    let year = '';
    if (dateValue.length > 0) {
      month = dateValue[0];
    }
    if (dateValue.length > 1) {
      year = dateValue[1];
    }
    this.setState(
      {
        expiryDate: date,
        expireMonth: month,
        expireYear: year,
      },
      () => {
        this.validateCardData(false, 'expire');
      },
    );
  }

  expireMonthAction(value: string): void {
    if (value.length > 2) {
      return;
    }
    this.setState({
      expireMonth: value,
    });
  }

  expireYearAction(value: string): void {
    if (value.length > 4) {
      return;
    }
    this.setState({
      expireYear: value,
    });
  }

  cvvAction(value: string): void {
    this.setState({cvv: value}, () => {
      this.validateCardData(false, 'cvv');
    });
  }

  zipAction(value: string): void {
    this.setState({zip: value}, () => {
      this.validateCardData(false, 'zip');
    });
  }

  switchAction(value: boolean): void {
    this.setState({defaultTokenStatus: value}, this.validateCardData);
  }

  checkExpiredDate(): boolean {
    const expireYear = this.state.expireYear as unknown as number;
    const expireMonth = this.state.expireMonth as unknown as number;
    const currentMonth = moment().format('MM') as unknown as number;
    const currentYear = moment().format('YY') as unknown as number;
    return (
      expireYear < currentYear ||
      (expireYear === currentYear && expireMonth < currentMonth) ||
      expireMonth > 12
    );
  }

  validateCardData(buttonClick = false, type = '') {
    let isValid = true;
    //Card Number Validation
    if (type == 'card' || buttonClick) {
      if (this.state.creditCard.length === 0) {
        this.setState({cardNumberError: Localized.Errors.card_number_required});
      } else if (
        this.state.creditCard.length >= 1 &&
        this.state.creditCard.length < 15
      ) {
        this.setState({cardNumberError: Localized.Errors.invalid_card_number});
      } else {
        this.setState({cardNumberError: null}, () => (isValid = true));
      }
    }

    //Expiration Date Validation
    if (type == 'expire' || buttonClick) {
      if (
        this.state.expireMonth.length === 0 &&
        this.state.expireYear.length === 0
      ) {
        this.setState({
          expDateError: Localized.Errors.expiration_date_required,
        });
      } else if (
        this.state.expireMonth.length !== 2 ||
        this.state.expireYear.length !== 2 ||
        this.checkExpiredDate()
      ) {
        this.setState({
          expDateError:
            Localized.Errors.card_has_expired_or_invalid_date_format,
        });
      } else {
        this.setState(
          {
            expDateError: null,
          },
          () => (isValid = true),
        );
      }
    }
    //CVV Validation
    if (type == 'cvv' || buttonClick) {
      if (this.state.cvv.length === 0) {
        this.setState({cvvError: Localized.Errors.security_code_required});
      } else if (this.state.cvv.length < 3 || this.state.cvv.length > 4) {
        this.setState({cvvError: Localized.Errors.invalid_security_code});
      } else {
        this.setState({cvvError: null}, () => (isValid = true));
      }
    }
    //Zip Code Validation
    if (type == 'zip' || buttonClick) {
      if (this.state.zip.length === 0) {
        this.setState({
          zipCodeError: Localized.Errors.zip_postal_code_required,
        });
      } else if (this.state.zip.length < 5 || this.state.zip.length > 6) {
        this.setState({
          zipCodeError: Localized.Errors.invalid_zip_code,
        });
      } else {
        this.setState(
          {
            zipCodeError: null,
          },
          () => (isValid = true),
        );
      }
    }
    if (
      this.state.zipCodeError ||
      this.state.cvvError ||
      this.state.expDateError ||
      this.state.cardNumberError ||
      !this.state.zip ||
      !this.state.cvv ||
      !this.state.expireMonth ||
      !this.state.expireYear ||
      !this.state.creditCard
    ) {
      isValid = false;
    }
    this.setState({isActive: isValid});
    if (isValid && buttonClick) {
      this.handleClick();
    }
  }
  componentDidUpdate(
    _prevProps: Readonly<CreditCardScreenProps>,
    prevState: Readonly<CreditCardScreenState>,
  ): void {
    if (
      this.state.zipCodeError !== prevState.zipCodeError ||
      this.state.cvvError !== prevState.cvvError ||
      this.state.expDateError !== prevState.expDateError ||
      this.state.cardNumberError !== prevState.cardNumberError
    ) {
      this.validateCardData(false);
    }
  }
  render() {
    if (this.state.cardScanned) {
      return (
        <BackSubheader
          previousRoute={this.state.previousRoute}
          accessibilityLabel={'Back arrow'}
          accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
          title={Localized.Labels.add_card}
          onBackSelect={this.onCloseSelect}
        >
          <KeyboardAvoidingView style={Styles.Style.flex} insideTab>
            <ScrollView
              contentContainerStyle={styles.scrollView}
              style={Styles.Style.maxWidthContainer}
              ref={(scrollView) => {
                this.scrollView = scrollView;
              }}
              automaticallyAdjustContentInsets={false}
            >
              <View style={styles.inputContainer}>
                <CreditCardInput
                  accessibilityLabel={Localized.Labels.card_number}
                  editable={false}
                  issuer={this.state.issuer}
                  value={this.state.redactedCardNumber}
                  label={Localized.Labels.card_number}
                />
                <View style={styles.row}>
                  <View style={styles.inputRowLeft}>
                    <AllyTextInput
                      keyboardType="numeric"
                      label={Localized.Labels.expiration_month}
                      value={this.state.expireMonth}
                      accessible={true}
                      accessibilityLabel={Localized.Labels.expiration_month}
                      accessibilityValue={{text: this.state.expireMonth}}
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
                      onChangeText={(text) =>
                        this.setState({expireMonth: text})
                      }
                    />
                  </View>
                  <View style={styles.inputRowRight}>
                    <AllyTextInput
                      keyboardType="numeric"
                      label={Localized.Labels.expiration_year + 'please'}
                      value={this.state.expireYear}
                      accessible={true}
                      accessibilityLabel={Localized.Labels.expiration_year}
                      accessibilityValue={{text: this.state.expireYear}}
                      onChangeText={(text) => this.setState({expireYear: text})}
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
                    />
                  </View>
                </View>

                <View style={styles.row}>
                  <View style={styles.inputRowLeft}>
                    <AllyTextInput
                      keyboardType="numeric"
                      label={Localized.Labels.cvv}
                      value={this.state.cvv}
                      accessible={true}
                      accessibilityLabel={Localized.Labels.cvv}
                      accessibilityValue={{text: this.state.cvv}}
                      accessibilityHint="CVV is found on the back of most credit cards"
                      onChangeText={(text) =>
                        this.setState({
                          cvv: text,
                        })
                      }
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
                    />
                  </View>

                  <View style={styles.inputRowRight}>
                    <AllyTextInput
                      keyboardType="numeric"
                      label={Localized.Labels.zip_code}
                      value={this.state.zip}
                      accessible={true}
                      accessibilityLabel={Localized.Labels.zip_code}
                      accessibilityValue={{text: this.state.zip}}
                      onChangeText={(text) => this.setState({zip: text})}
                      onSubmitEditing={this.handleClick}
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
                    />
                  </View>
                </View>
              </View>

              <View style={[styles.defaultStatusView]}>
                <AVText
                  accessibilityLabel={Localized.Labels.set_as_default}
                  accessible={true}
                  style={styles.setDefaultLabel}
                  aria-label={`${Localized.Labels.set_as_default} label, text`}
                  accessibilityRole="text"
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
                >
                  {Localized.Labels.set_as_default}
                </AVText>

                <CustomToggleSwitch
                  accessibilityLabel={Localized.Labels.set_as_default}
                  testID="setAsDefaultScanNewCard"
                  value={this.state.defaultTokenStatus}
                  accessibilityState={{
                    checked: this.state.defaultTokenStatus,
                    disabled: this.state.isDefaultTokenDisabled,
                  }}
                  isDisabled={this.state.isDefaultTokenDisabled}
                  onValueChange={(value) => {
                    this.setState({defaultTokenStatus: value});
                  }}
                />
              </View>
            </ScrollView>
            <RoundedButton
              accessible={true}
              accessibilityLabel={`${this.props.buttonText}`}
              accessibilityRole="button"
              aria-label={`${this.props.buttonText}`}
              role="button"
              buttonType={ButtonType.action}
              onPress={this.handleClick}
              text={this.props.buttonText}
              color={null}
              containerStyle={null}
              textStyle={null}
            />
          </KeyboardAvoidingView>
        </BackSubheader>
      );
    }

    return (
      <BackSubheader
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        title={getCreditCardDescriber().getHeaderTitle()}
      >
        <KeyboardAvoidingView style={Styles.Style.flex} insideTab>
          <ScrollView
            style={Styles.Style.maxWidthContainer}
            ref={(scrollView) => {
              this.scrollView = scrollView;
            }}
            keyboardDismissMode="interactive"
            automaticallyAdjustContentInsets={false}
            keyboardShouldPersistTaps="handled"
          >
            {getCreditCardDescriber().getAddCardDescriptionView()}
            <View style={styles.inputContainer}>
              {getCreditCardDescriber().getAddCardInputView(
                this.state.creditCard,
                this.state.expireMonth,
                this.state.expireYear,
                this.state.cvv,
                this.state.zip,
                this.state.cardNumberError,
                this.state.expDateError,
                this.state.cvvError,
                this.state.zipCodeError,
                this.cardNumberAction,
                this.expireDateAction,
                this.expireYearAction,
                this.cvvAction,
                this.zipAction,
                this.state.expiryDate,
                this.state.allowChangeText,
                this.changeTxtAction,
              )}
              {getCreditCardDescriber().getDefaultSwitchStatusView(
                this.state.isDefaultTokenDisabled,
                this.state.defaultTokenStatus,
                this.switchAction,
              )}
            </View>
          </ScrollView>
          {getCreditCardDescriber().getCardSaveButton(
            this.validateCardData,
            this.props.buttonText,
            this.state.isActive,
            this.state.isActive,
          )}
        </KeyboardAvoidingView>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  absoluteTextContainer: {
    backgroundColor: Styles.white,
    bottom: 0,
    height: 300,
    left: 0,
    position: 'absolute',
    right: 0,
    zIndex: 2,
  },
  cardView: {
    flex: 1,
  },
  cardViewContainer: {
    flex: 1,
    marginTop: -300,
    zIndex: 1,
  },
  ccInput: {},
  input: {},
  inputContainer: {
    flexDirection: 'column',
    marginHorizontal: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m1,
    paddingBottom: Styles.Spacing.m5,
    padding: Settings.isNewUI() ? Styles.Spacing.m2 : 0,
  },
  inputRowLeft: {
    flex: 0.5,
    paddingRight: Styles.Spacing.m2,
  },
  inputRowRight: {
    flex: 0.5,
    paddingLeft: Styles.Spacing.m1,
  },
  instructionText: {
    alignSelf: 'center',
    fontSize: Styles.Fonts.f1,
    marginTop: Styles.Spacing.m2,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  scanBtn: {
    marginTop: Styles.Spacing.m2,
  },
  scrollView: {
    alignSelf: 'stretch',
  },
  tetriaryBtn: {
    alignSelf: 'center',
    bottom: Styles.Spacing.m4,
    position: 'absolute',
    zIndex: 3,
  },
  defaultStatusView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m2 + Styles.Spacing.m1,
  },
  defaultSwitch: {
    transform: [
      {
        scaleX: 0.85,
      },
      {
        scaleY: 0.85,
      },
    ],
  },
  setDefaultLabel: {
    fontSize: Styles.Fonts.f1,
    marginRight: Styles.Spacing.m2,
    marginLeft: Styles.Spacing.m1,
    fontWeight: '400',
  },
});

const ConnectedCardsScreen = connect((state: RootState) => ({
  paymentCredentials: state.account.paymentCredentials,
  creditCards: state.account.creditCards,
  selectedLocation: state.campusLocation.selectedLocation,
}))(CreditCardScreen);

export default withForwardedNavigationParams<any>()(ConnectedCardsScreen);
